import React from "react";
import "./App.scss";
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';

import Page from './Page';

//TODO: href link with offset
// TODO: hover states on images
// TODO: map and ocntact form
//TODO: mobile resposive font
// TODO: wrap cols on smaller screens

const RECAPTCHA_KEY = "6Le11D8cAAAAACBQJ9ygKcdsRb93UpAac_2dc4jt";

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY} scriptProps={{defer: true}}>
      <Page />
    </GoogleReCaptchaProvider>
  );
}

export default App;
