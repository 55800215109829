import React, {useCallback} from "react";
import britishFlag from "./british flag learn english in ceret.webp";
import janImg from "./Jan-belshaw-french-english-teacher.webp";
import teacherImg from "./learn-english-janet-belshaw-English-Teacher.webp";
import onlineTeacherImg from "./learn-english-janet-belshaw-online-English-Teacher.webp";
import kidsClubImg from "./LCF-kids-club-ceret.webp";
import cambridgeEnglishImg from "./cambridge-exams-learn-english.webp";
import individualCourseImg from "./cours-particulier-learn-english.webp";
import groupCourseImg from "./cours-collectifs-learn-english.webp";
import businessCourseImg from "./cours-anglais-affaires-learn-english.webp";
import "./App.scss";
import { Helmet } from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";
import { FaFacebookF } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { VscMail } from "react-icons/vsc";
import {
  useGoogleReCaptcha,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';

function App() {
  const [state, setState] = React.useState({});
  const [confirmMessage, setConfirmMessage] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(true);
  const [token, setToken] = React.useState(null);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    if (errorMessage) setErrorMessage(null);
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      // Do nothing
    }
    const token = await executeRecaptcha('contact');
    setToken(token);
    setButtonDisabled(false);
  }, [executeRecaptcha]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        "form-name": form.getAttribute("name"),
        "g-recaptcha-response": token,
        ...state,
      }).toString(),
    })
      .then((res) => {
        if (res.ok) {
          setConfirmMessage(true);
        } else {
          setErrorMessage(true);
        }
      })
      .catch((error) => {
        setErrorMessage(true);
      });
  };

    React.useEffect(() => {
      if (confirmMessage) setTimeout(() => {setConfirmMessage(false);}, 5000);

    }, [confirmMessage]);

  return (
      <div className="App">
      <Helmet>
        <title>Learn English in Céret</title>
        <meta
          name="description"
          content="Cours d'anglais - learn English in Céret - élargir vos connaissances"
        />
      </Helmet>
      <div>
        <header>
          <Row id="banner-row" className="d-flex justify-content-between">
            <Col sm={12} md={6} className="d-flex p-3">
              <a href="#home" target="_self">
                <img
                  src={britishFlag}
                  alt="british-learn-english-in-ceret"
                ></img>
              </a>
              <div className="ml-2">
                <p id="company-name" className="m-0">
                  LEARN ENGLISH IN CÉRET
                </p>
                <p id="tag-line" className="m-0">
                  avec Janet Belshaw
                </p>
              </div>
            </Col>
          </Row>
        </header>
        <Navbar id="nav-row" className="sticky-top">
            <Nav className="mr-auto">
              <Nav.Link href="#cours">Parcourir les cours</Nav.Link>
              <Nav.Link href="#contact">Réserver un cours</Nav.Link>
            </Nav>
            <a
              id="nav-email"
              href="mailto:info@learnenglishinceret.com?subject=Demande d’info"
              className="nav-links"
            >
              info@learnenglishinceret.com
            </a>
            <a
              id="nav-email-icon"
              href="mailto:info@learnenglishinceret.com?subject=Demande d’info"
              className="nav-links"
            >
              <VscMail />
            </a>
            <a id="nav-tel" href="tel:+33665078140" className="nav-links">
              +33 6 65 07 81 40
            </a>
            <a id="nav-tel-icon" href="tel:+33665078140" className="nav-links">
              <FaPhone />
            </a>
            <a
              href="https://www.facebook.com/LCFenglishinceret"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF />
            </a>
          </Navbar>
        <div>
          <main>
            <section
              className="section parallax overlay hero-section"
              id="home"
            >
              <div className="hero-contents">
                <div id="hero-title">
                  <h1>Apprendre L'Anglais à Céret</h1>
                </div>
                <div id="hero-key-benefits">
                  <ul>
                    <li>Elargir vos connaissances</li>
                    <li>Elargir vos horizons</li>
                    <li>Répondre à tous vos besoins linguistiques</li>
                  </ul>
                </div>
                <div id="hero-cta">
                  <a
                    href="#contact"
                    target="_self"
                    className="btn"
                  >
                    Réserver un cours
                  </a>
                </div>
              </div>
            </section>
            <section class="section" id="about-me-section">
              <div className="d-flex flex-wrap img-text-split-contents">
                <div className="col-sm-auto col-xs-12 d-flex align-items-center justify-content-center">
                  <img
                    height="auto"
                    width="240px"
                    src={janImg}
                    alt="jan-belshaw-french-english-teacher"
                  ></img>
                </div>
                <div className="col-sm col-auto col-auto grid-content mt-3">
                  <h1>VOICI MON HISTOIRE</h1>
                  <p className="font-weight-bold">
                    En tant qu’enseignante professionnelle comptant 18 ans
                    d’expérience dans le domaine, je suis à votre service.
                  </p>
                  <p>
                    De nationalité britannique avec une licence en français des
                    affaires, j’ai commencé ma vie d'enseignante en 2002 en
                    Angleterre comme professeur de français.
                  </p>
                  <p>
                    Je suis diplômée en Cambridge CELTA-TESOL depuis 2011, et
                    gérante de LCF English in Céret depuis 2016 où j’enseigne
                    l’anglais aux enfants, aux adolescents et aux adultes.
                  </p>
                  <p>
                    Avant de m’installer à Céret, j’ai été gérante de ma propre
                    Ecole de Langues en Angleterre pendant 14 ans. Auparavant,
                    j’ai travaillé dans les domaines du management et du service
                    clientèle dans l’industrie aérienne.
                  </p>
                  <p>
                    Chacun a de bonnes raisons d’apprendre ou de développer une
                    langue, et chacun a son mode personnel d’apprentissage. Mon
                    travail est de vous aider à développer votre propre style,
                    de comprendre vos exigences linguistiques précises et
                    d’adapter mon enseignement en conséquence.
                  </p>
                </div>
              </div>
            </section>
            <div className="text-center" id="cta-after-about">
              <a href="#contact" target="_self">
              <button className="btn">
                Contactez-moi pour trouver comment je peux vous aider à
                atteindre vos objectifs.
              </button></a>
            </div>
            <section class="section" id="course-location-section">
              <div className="text-center">
                <h1>LIEUX DES COURS</h1>
                <div className="row">
                  <div className="col-xs-12 col-md-6 col-sm-12 pb-3 d-flex justify-content-center">
                    <Card style={{ maxWidth: "25rem", height: "100%" }}>
                      <Card.Img variant="top" src={teacherImg} />
                      <Card.Body>
                        <Card.Title>En personne</Card.Title>
                        <Card.Text>
                          Chez vous, chez moi ou votre établissement choisi aux
                          alentours de Céret/Perpignan.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="col-xs-12 col-md-6 col-sm-12 pb-3 d-flex justify-content-center">
                    <Card style={{ maxWidth: "25rem", height: "100%" }}>
                      <Card.Img variant="top" src={onlineTeacherImg} />
                      <Card.Body>
                        <Card.Title>En ligne</Card.Title>
                        <Card.Text>À votre convenance.</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            </section>
            <section class="section teaching-details-section" id="cours">
              <div className="text-center pt-3">
                <h1>PARCOURIR LES COURS</h1>
                <h5>Rendre l’apprentissage plus agréable</h5>
                <div className="row pt-3">
                  <div className="col-xs-12 col-md-6 col-sm-12 pb-3 d-flex justify-content-center">
                    <Card style={{ maxWidth: "30rem", height: "100%" }}>
                      <Card.Img variant="top" src={kidsClubImg} />
                    </Card>
                  </div>
                  <div className="col-xs-12 col-md-6 col-sm-12 pb-3 d-flex justify-content-center">
                    <Card
                      style={{
                        maxWidth: "30rem",
                        height: "100%",
                        border: "10px solid #f0e5e5",
                      }}
                    >
                      <Card.Body
                        className="d-flex justify-content-center h-100"
                        style={{ flexDirection: "column", padding: "20px" }}
                      >
                        <Card.Title>LCF KIDS CLUB</Card.Title>
                        <Card.Text>
                          LCF Kids Club fait partie de LCF Clubs qui sont
                          spécialisés dans l'enseignement des langues aux
                          enfants de 3 à 12 ans. LCF est établi
                          internationalement depuis 1985. Notre formule ludique
                          est appréciée de tous. Elle s'appuie sur une méthode
                          efficace et soigneusement structurée qui stimule
                          l'aptitude naturelle des enfants pour les langues.
                        </Card.Text>
                        <Card.Text>
                          Les enfants aiment nos méthodes d'enseignement qui
                          comprennent des jeux, de la musique, des chansons, des
                          petits travaux manuels ainsi que les méthodes
                          traditionnelles.
                        </Card.Text>
                        <Card.Text>
                          Les membres du Kids Club peuvent aussi apprendre à la
                          maison avec notre site de <a
                            href="https://www.lcfclubs.com/englishzone/" target="_blank"
                            rel="noopener noreferrer">jeux interactif</a>.</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-xs-12 col-md-6 col-sm-12 pb-3 d-flex justify-content-center">
                    <Card
                      style={{
                        maxWidth: "30rem",
                        height: "100%",
                        border: "10px solid #f0e5e5",
                      }}
                    >
                      <Card.Body
                        className="d-flex justify-content-center h-100"
                        style={{ flexDirection: "column", padding: "20px" }}
                      >
                        <Card.Title>CAMBRIDGE ASSESSMENT ENGLISH</Card.Title>
                        <Card.Text>
                          La préparation pour les examens de <a href="https://www.cambridgeenglish.org/fr/learning-english/" target="_blank"
                            rel="noopener noreferrer">Cambridge English</a> pour tous niveaux:
                          <ul>
                          <li>du niveau pre-A1 (destiné aux élèves de l’école primaire)</li>
                          <li>tous les niveaux A1 / A2 / B1 / B2 / C1</li>
                          <li>jusqu’au niveau C2 (destiné aux individus qui voulant atteindre un niveau exceptionnel en anglais)</li>
                          </ul>
                        </Card.Text>
                        <Card.Text>
                        “Préparer les examens d'anglais permet d'acquérir ou de renforcer ses connaissances en travaillant sur les quatre
                        compétences clés de l'apprentissage de l'anglais : la compréhension écrite et orale et l'expression écrite et orale.”
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-xs-12 col-md-6 col-sm-12 pb-3 d-flex justify-content-center">
                    <Card style={{ maxWidth: "30rem", height: "100%" }}>
                      <Card.Img variant="top" src={cambridgeEnglishImg} />
                    </Card>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-xs-12 col-md-6 col-sm-12 pb-3 d-flex justify-content-center">
                    <Card style={{ maxWidth: "30rem", height: "100%" }}>
                      <Card.Img variant="top" src={individualCourseImg} />
                    </Card>
                  </div>
                  <div className="col-xs-12 col-md-6 col-sm-12 pb-3 d-flex justify-content-center">
                    <Card
                      style={{
                        maxWidth: "30rem",
                        height: "100%",
                        border: "10px solid #f0e5e5",
                      }}
                    >
                      <Card.Body
                        className="d-flex justify-content-center h-100"
                        style={{ flexDirection: "column", padding: "20px" }}
                      >
                        <Card.Title>LES COURS PARTICULIER</Card.Title>
                        <Card.Text>
                          Bénéficier d’une attention personnalisée.
                        </Card.Text>
                        <Card.Text>
                          Mon parcours professionnel est divers et il comprend 18 ans dans l’enseignement. Vous pourrez ainsi profiter de mes différentes expériences professionnelles et recevoir un enseignement personnalisé de qualité.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-xs-12 col-md-6 col-sm-12 pb-3 d-flex justify-content-center">
                    <Card
                      style={{
                        maxWidth: "30rem",
                        height: "100%",
                        border: "10px solid #f0e5e5",
                      }}
                    >
                      <Card.Body
                        className="d-flex justify-content-center h-100"
                        style={{ flexDirection: "column", padding: "20px" }}
                      >
                        <Card.Title>LES COURS COLLECTIFS</Card.Title>
                        <Card.Text>
                          C’est agréable d’apprendre une langue en groupe.  Il peut y avoir plusieurs étudiants dans une même classe en cours collectif et chacun dans mes cours est suivi personnellement.
                        </Card.Text>
                        <Card.Text>
                          J’utilise régulièrement des méthodes ludiques dans le cadre de mes cours d’adultes, ce qui sont bien appréciées par mes étudiants.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-xs-12 col-md-6 col-sm-12 pb-3 d-flex justify-content-center">
                    <Card style={{ maxWidth: "30rem", height: "100%" }}>
                      <Card.Img variant="top" src={groupCourseImg} />
                    </Card>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-xs-12 col-md-6 col-sm-12 pb-3 d-flex justify-content-center">
                    <Card style={{ maxWidth: "30rem", height: "100%" }}>
                      <Card.Img variant="top" src={businessCourseImg} />
                    </Card>
                  </div>
                  <div className="col-xs-12 col-md-6 col-sm-12 pb-3 d-flex justify-content-center">
                    <Card
                      style={{
                        maxWidth: "30rem",
                        height: "100%",
                        border: "10px solid #f0e5e5",
                      }}
                    >
                      <Card.Body
                        className="d-flex justify-content-center h-100"
                        style={{ flexDirection: "column", padding: "20px" }}
                      >
                        <Card.Title>LES COURS D’ANGLAIS DES AFFAIRES</Card.Title>
                        <Card.Text>
                          Expérimentée et munie d’une licence en français des affaires et d’un diplôme en gestion, j'ai aussi occupé des postes professionnels en business, je possède toutes les qualités pour effectuer ce travail spécialisé. Donc, permettez-moi de vous aider à vous surpasser.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            </section>
            <section class="section review-section">
              <div class="review-box">
                <div className="p-3">
                <p className="feedback-comment">
Shania retrouve l'amour de l'apprentissage de l'anglais malgré la dyslexie grace aux ateliers de Janet. Merci beaucoup</p>
                <p className="feedback-from"><a href="https://www.facebook.com/LCFenglishinceret/reviews" target="_blank"
              rel="noopener noreferrer">Cynthia Bontems</a></p>
                </div>
              </div>
            </section>
            <section class="section" id="contact">
              <div className="text-center pt-3">
                <h1>Demande de réservation ou d’information</h1>
                <h5>
                  Je suis là pour vous aider dans le processus de
                  l’apprentissage. Contactez-moi afin de voir comment je peux
                  vous être utile.
                </h5>
                <form
                  name="contact"
                  method="POST"
                  action="/"
                  data-netlify="true"
                  data-netlify-recaptcha="true"
                  onSubmit={handleSubmit}
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <p>
                    <input
                      name="name"
                      id="contact-name"
                      className="text-input"
                      autocomplete="name"
                      type="text"
                      placeholder="Nom"
                      required
                      aria-label="Nom"
                      onChange={handleChange}
                    ></input>
                  </p>
                  <p>
                    <input
                      name="email"
                      id="contact-email"
                      className="text-input"
                      autocomplete="email"
                      type="email"
                      placeholder="Email"
                      required
                      aria-label="Email"
                      onChange={handleChange}
                    ></input>
                  </p>
                  <p>
                    <fieldset>
                      <legend>J’aimerais en savoir plus sur:</legend>
                      <div>
                        <input
                          type="checkbox"
                          id="kids-club"
                          name="interest-kids-club"
                          value="kids-club"
                          onChange={handleChange}
                        />
                        <label for="kids-club" className="pl-2">
                          LCF Kids Club
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="individual-cours"
                          name="interest-individual-cours"
                          value="individual-course"
                          onChange={handleChange}
                        />
                        <label for="individual-cours" className="pl-2">
                          Cours particulier
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="group-course"
                          name="interest-group-course"
                          value="group-course"
                          onChange={handleChange}
                        />
                        <label for="group-course" className="pl-2">
                          Cours collectif
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="cambridge-english"
                          name="interest-cambridge-english"
                          value="cambridge-english"
                          onChange={handleChange}
                        />
                        <label for="cambridge-english" className="pl-2">
                          Préparation pour Cambridge English
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="business-english"
                          name="interest-business-english"
                          value="business-english"
                          onChange={handleChange}
                        />
                        <label for="business-english" className="pl-2">
                          Cours d’anglais des affaires
                        </label>
                      </div>
                    </fieldset>
                  </p>
                  <p>
                    <textarea
                      id="contact-message"
                      name="contact-message"
                      className="text-input"
                      placeholder="Mon message..."
                      onChange={handleChange}
                    ></textarea>
                  </p>

                  <GoogleReCaptcha onVerify={handleReCaptchaVerify} scriptProps={{
                    // async: true, // optional, default to false,
                    defer: true, // optional, default to false
                    appendTo: "body", // optional, default to "head", can be "head" or "body",
                    // nonce: undefined // optional, default undefined
                  }} />
                  <button type="submit" disabled={buttonDisabled} className="submit-btn">
                    Envoi
                  </button>
                  {confirmMessage && <p className="confirm-message">Merci pour votre message.</p>}
                  {errorMessage && <p className="confirm-message">Désolé, erreur d'envoi du message. Veuillez m'envoyer un <a href="mailto:info@learnenglishinceret.com?subject=Demande d’info">email</a>.</p>}
                </form>
              </div>
            </section>
          </main>
          <foooter>
            <div className="d-flex justify-content-center align-items-center top-footer">
              <div>
                <a href="tel:+33665078140" className="nav-links">
                  +33 6 65 07 81 40
                </a>
              </div>
              <div>
                <a
                  href="mailto:info@learnenglishinceret.com"
                  className="nav-links"
                >
                  info@learnenglishinceret.com
                </a>
              </div>
              <div>
                <a
                  href="https://www.facebook.com/LCFenglishinceret"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF />
                </a>
              </div>
            </div>
            <div className="bottom-border"></div>
          </foooter>
        </div>
      </div>
    </div>
  );
}

export default App;
